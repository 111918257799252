import React, { useEffect, useState } from "react";
import "./styles.scss";
import { getRecordings } from "../../contentful";
import RecordingCard from "../../components/RecordingCard/RecordingCard";

const Discography = () => {
  const [cds, setCds] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getRecordings().then((data) => setCds(data));
  }, []);

  return (
    <div className='page-contents mediapage-container'>
      <div className='contents-container'>
        <div className='recording-cards-container'>
          {cds.map((cd) => (
            <RecordingCard recording={cd.fields} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Discography;
