import React, { useState, useEffect } from "react";
import "./ImpressumStyles.scss";
import marked from "marked";
import { getImpressum } from "../../contentful";

const Impressum = () => {
  const [text, setText] = useState("");

  useEffect(() => {
    getImpressum().then((data) => setText(data[0].fields.text));
  }, []);
  return (
    <div className='page-contents'>
      <div className='inner-container main-container'>
        {text.length && (
          <div dangerouslySetInnerHTML={{ __html: marked(text) }} />
        )}
      </div>
    </div>
  );
};

export default Impressum;
