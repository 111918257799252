import React from "react";
import "./FooterStyles.scss";

const Footer = () => {
  return (
    <div className='footer-container'>
      <p>Design by Jürgen Sauerhöfer </p>
      <p>
        Developed by <a href='https://webspinner.eu'>WebSpinner.eu</a>
      </p>
    </div>
  );
};

export default Footer;
