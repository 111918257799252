import { createStore } from "redux";
const initialState = {
  appBarTitle: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "update-titlebar":
      return {
        ...state,
        appBarTitle: action.payload,
      };
    default:
      return state;
  }
};

const store = createStore(reducer);

export default store;
