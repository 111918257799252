import React from "react";
import { NavLink } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import "./SideDrawerStyles.scss";

const SideDrawer = ({ open, onClose, openContact, setHeaderTitle }) => {
  const handleClick = () => {
    onClose();
  };

  const routes = [
    // { label: "Ensemble", path: "/ensemble" },
    { label: "Programme", path: "/programme" },
    { label: "Medien", path: "/medien" },
    // { label: "Diskographie", path: "/diskographie" },
    { label: "Impressum", path: "/impressum" },
  ];
  return (
    <Drawer open={open} onClose={onClose}>
      <div className='light-background drawer-container'>
        <NavLink
          onClick={handleClick}
          style={{ textDecoration: "none" }}
          to='/ensemble'
        >
          <h1 style={{ color: "white" }}>Capricornus Ensemble Stuttgart</h1>
        </NavLink>
        {routes.map((route) => (
          <NavLink
            key={route.path}
            onClick={handleClick}
            className='nav-link'
            to={route.path}
          >
            {route.label}
          </NavLink>
        ))}

        <span
          style={{ cursor: " pointer" }}
          onClick={() => {
            openContact();
            onClose();
          }}
          className='nav-link'
        >
          Kontakt
        </span>
      </div>
    </Drawer>
  );
};

export default SideDrawer;
