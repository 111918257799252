import React from "react";
import "./HomeHeroStyles.scss";
import Henning from "../../assets/hero-image.png";

const HomeHero = () => {
  return (
    <div className='hero-container'>
      <div className='hero-title'>
        <span className='hero-title__text'>capricornus</span>
        <span className='hero-title__text'>ensemble</span>
        <span className='hero-title__text'>stuttgart</span>
      </div>
      <img src={Henning} alt='Henning' />
    </div>
  );
};

export default HomeHero;
