import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import "./globalStyles.scss";
import { useDispatch } from "react-redux";
import Appbar from "./components/Appbar/Appbar";
import Homepage from "./pages/Homepage/Homepage";
import Footer from "./components/Footer/Footer";
import ContactModal from "./components/ContactModal/ContactModal";
import Portrait from "./pages/Portrait/Portrait";
import Medien from "./pages/Medien";
import Programme from "./pages/Programme";
import Impressum from "./pages/Impressum/Impressum";
import Discography from "./pages/Discography";

const App = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: "update-titlebar",
      payload: location.pathname.split("/")[1],
    });
  }, [location]);
  const [contactModal, setContactModal] = useState(false);
  return (
    <div>
      <Appbar setContactModal={setContactModal} />
      <ContactModal
        open={contactModal}
        onClose={() => setContactModal(false)}
      />
      <div style={{ height: 55 }} />
      <Switch>
        {/* <Route path='/aktuell' component={Homepage} /> */}
        <Route path='/ensemble' component={Portrait} />
        <Route path='/medien' component={Medien} />
        <Route path='/programme' component={Programme} />
        <Route path='/impressum' component={Impressum} />
        <Route path='/diskographie' component={Discography} />
        <Redirect from='/' exact to='/ensemble' />
      </Switch>
      <Footer />
    </div>
  );
};

export default App;
